import React from "react";
import imageOne from "../../assets/image/services/ocorie-services-illust-cnslt.png";

const ConsultingContent = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className='container'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-xl-6 col-lg-7 col-md-9 order-1 order-lg-1'>
            <div className='mt-9 mt-lg-0' data-aos='fade-right' data-aos-delay={300} data-aos-once='true'>
              <h6 className='font-size-3 text-dodger-blue-1 text-uppercase mb-9 letter-spacing-normal'>
                Best IT Consultation Providers
              </h6>
              <h2 className='font-size-10 mb-8 letter-spacing-n83'>IT Consulting</h2>
              <p className='justify-text font-size-7 mb-0'>
                Our consulting services are top-notch. Ocorie gives firms professional and bespoke advice to organizations, agencies, institutions and individuals in all areas of information technology.<br/><br />
                <p className="heading-default-color line-height-25">Our consulting services include:</p>Cloud adoption decision-making matrix
                <br />	Comprehensive risk assessment of cloud computing
                <br />	Security Audits.
                <br />	ISO 27001 certification
                <br />	Infrastructure-as-a-service (IAAS)
                <br />	Platform-as-a-service (PAAS)
                <br />	Software-as-a-service (SAAS)
                <br />	Design and development of courses, curricula and courseware 
                <br />	Setting up Information security units in organizations
                <br />	Standard Operations Procedure for centers (Centre SOP) 
                <br />	Organizational and information security policies
              </p>
            </div>
          </div>
          <div className='col-xl-5 offset-xl-1 col-lg-5 col-md-8 col-xs-11 order-2 order-lg-2'>
            <div
              className='l4-content-img-2 ml-lg-10 ml-xl-7 rounded-10 text-center'
              data-aos='fade-left'
              data-aos-duration={300}
              data-aos-delay={100}
              data-aos-once='true'>
              <img className='mt-7 w-75 w-md-100' src={imageOne} alt='' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultingContent;
