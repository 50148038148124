import React from "react";

import imgM from "../../assets/image/services/ocorie-services-illust-train.png";
import imgS from "../../assets/image/svg/circ-oc.svg";

const TrainingContent = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row align-items-center justify-content-center justify-content-lg-start">
          {/* Image Section */}
          <div className="col-xl-5 col-xs-4">
            <div className="l6-content-image-group-2">
              <div className="img-1">
                <img
                  className="w-100"
                  src={imgS}
                  alt=""
                  data-aos="fade-up"
                  data-aos-delay={300}
                />
              </div>
              <div className="img-2">
                <img
                  className="w-100"
                  src={imgM}
                  alt=""
                  data-aos="fade-right"
                  data-aos-delay={100}
                />
              </div>
            </div>
          </div>
          {/* Content Section */}
          <div className="col-lg-6 col-md-8 col-xs-10 ml-lg-10 ml-xl-10">
            <div className="pt-7 pb-4 pb-lg-9 pl-sm-5 pl-md-9 pl-lg-10">
              <h2
                className="font-size-11 mb-9 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                IT Training
              </h2>
              <p
                className="justify-text font-size-7 mb-0 pr-xl-13"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                Ocorie Systems is a dependable technology training partner. We transform people into full-fledged information security experts. Courses are taught by Information security practitioners with decades of real world experience. Our certified trainers go the extra mile to guide you through the attainment of certifications.<br />
                We also offer public speaking at events and seminars to create awareness. We offer executive leadership training for corporate risk management and security governance.

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingContent;
