import React from "react";

import imgM from "../../assets/image/services/ocorie-services-illust-staff.png";
import imgG from '../../assets/image/dots/dot-pattern-purple.svg';

const StaffingContent = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row align-items-center justify-content-center justify-content-lg-start">
          {/* Image Section */}
          <div className="col-xl-5 col-xs-4">
            <div className="l6-content-image-group">
              <div className="img-1">
                <img
                  className="w-100"
                  src={imgG}
                  alt=""
                  data-aos="fade-down"
                  data-aos-delay={300}
                />
              </div>
              <div className="img-2">
                <img
                  className="w-100"
                  src={imgM}
                  alt=""
                  data-aos="fade-right"
                  data-aos-delay={100}
                />
              </div>
            </div>
          </div>
          {/* Content Section */}
          <div className="col-lg-6 col-md-8 col-xs-10 ml-lg-10 ml-xl-10">
            <div className="pt-7 pb-7 pb-lg-17 pl-sm-5 pl-md-9 pl-lg-10">
              <h2
                className="font-size-11 mb-9 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                IT Staffing
              </h2>
              <p
                className="justify-text font-size-7 mb-0 pr-xl-13"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                At Ocorie, we screen the best and brightest professionals for IT management, cybersecurity professionals, network engineers, programmers, general IT practitioners, and graphic/web designers, etc. <br/>We liaise with industry players – both local and abroad- to ascertain their IT staffing needs and pitch trained students to them for onward engagement. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffingContent;
